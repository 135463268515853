// eslint-disable-next-line import/named
import { awaitableTemplate } from '../../../../webmodule-common/other/ui/template-processor.js';
import type { TableColumnConfiguration } from '../../../api/dealer-api-interface-franchisee.js';
import { BranchQuoteSupportStatus } from '../../../api/dealer-api-interface-franchisee.js';
import { classMap } from 'lit/directives/class-map.js';
import { constructAsync } from '../../../../webmodule-common/other/async-constructor.js';
import { consume } from '@lit/context';
import { isCynclyStaff, isSupplierAgent } from '../../../../webmodule-common/other/currentuser-claims.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding.js';
import { DataTracker, FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import { firstValidString, isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions.js';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant.js';
import type { FrameBuyInItem, QCQuoteValues, QCValue } from '../../../../webmodule-common/interop/webmodule-interop.js';
import { FrameValidationType } from '../../../../webmodule-common/interop/webmodule-interop.js';
import { getApiFactory } from '../../../api/api-injector.js';
import { getBuyInDetailsTemplate, getFrameDetailsTemplate } from '../../../frames/ui.js';
import {
  getQuoteTitleStr,
  isAnyFreehand,
  isDealerQuotePriceAdjustment,
  isDefaultShipping,
  isFrame,
  isShipping,
  isSpecialItem,
  isSSI,
  quoteItemThumbSrc
} from '../../../quotes/data/quote-helper-functions.js';
import { getV6FrameForContainer, getV6QuoteItemForContainer, isV6 } from '../../../quotes/data/v6/helper-functions.js';
import { getV6QuoteItemDisplayHeightFirst, getV6QuoteItemFrameDimensions } from '../../../v6config/v6-ui.js';
import { highestValidationRank, rankToValidationType } from '../../../v6config/validation-rank.js';
import type { PropertyValues } from 'lit';
import { html, LitElement, nothing } from 'lit';
import type { ImagePreview } from '../../../../webmodule-common/other/context/imagePreview.js';
import { imagePreviewContext } from '../../../../webmodule-common/other/context/imagePreview.js';
import { isDebugMode } from '../../../../webmodule-common/other/debug.js';
import { isValidV6ConfigVersion, v6SupportsVersion, v6VersionMap } from '../../../v6config/v6config.js';
import { lang, tlang } from '../../../../webmodule-common/other/language/lang.js';
import {
  moneyToTemplateResult,
  number2dpToHtml,
  number4dpToHtml
} from '../../../../webmodule-common/other/currency-formatter.js';
import { monitor } from '../../../../webmodule-common/other/monitor.js';
import { quoteItemAction } from '../../../quotes/data/events.js';
import { QuoteItemsView, QuoteItemsViewOptions, SettingsManager } from '../../../quotes/views/quote-items-view.js';
import type { QuoteItem } from '../../../api/dealer-api-interface-quote.js';
import { QuoteState } from '../../../api/dealer-api-interface-quote.js';
import { saveWithIndicator } from '../../../../webmodule-common/other/save-workflow.js';
import { supplierItemContentTypeDisplay } from '../../../quotes/data/supplier-quote-item-content-type.js';
import { tryAsNumberString } from '../../../../webmodule-common/other/number-utilities.js';
import { userDataStore } from '../../common/current-user-data-store.js';
import { V6BomViewer } from './v6/franchisee-v6-bom-viewer.js';
import { when } from 'lit/directives/when.js';
import WebmoduleIcon from '../../../../webmodule-common/components/src/components/icon/icon.js';
import type { EventTemplate } from '../../../../webmodule-common/other/ui/events.js';
import type { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager.js';
import type { QuoteApi } from '../../../api/quote-api.js';
import type { QuoteItemContainer } from '../../../quotes/data/quote-item-container.js';
import type { TemplateResult } from 'lit-html';
import type { V6FranchiseeQuoteProviderData } from '../data/franchisee-quote-provider-data.js';
import type {
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleSelectEvent,
  WebModuleTableItemMove
} from '../../../../webmodule-common/components/src/webmodule-components.js';
import {
  addQuotePriceSummaryToDataTracker,
  convertQuoteToQuotePriceSummaryDataContainer,
  QuoteDealerPriceAdjustmentDialog,
  QuotePriceSummaryDataContainer
} from './quote-dealer-price-adjustment-dialog';

@customElement('franchisee-quote-item-table')
export class FranchiseeQuoteItemTable extends LitElement {
  quoteApi: QuoteApi = getApiFactory().quote();
  @query('#quote-items-table')
  table?: WebModuleLitTable;
  @property({
    type: Array,
    hasChanged(newVal: object, oldVal: object) {
      return newVal !== oldVal;
    }
  })
  public columnConfiguration: TableColumnConfiguration[] = [];
  @property({ type: Object })
  public quoteManager!: FranchiseeQuoteContainerManager;

  @consume({ context: imagePreviewContext })
  @property({ attribute: false })
  public imagePreview?: ImagePreview;

  @state()
  private _columnsData: WebModuleLitTableColumnDef[] = [];
  @state()
  private _data: QuoteItem[] = [];
  @state()
  private _columns: WebModuleLitTableColumnDef[] = [];
  @state()
  private _expandedOptions = false;

  dispatchCustom(values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent(`wm-quote-item-action`, options));
  }

  @monitor('columnConfiguration', { delayUntilFirstUpdate: true })
  public updateCols() {
    const colDisplay = this._columnsData.map(x => {
      const config = this.columnConfiguration.find(y => y.code == x.id);

      return { visible: config?.defaultDisplay, ...x };
    });

    this._columns = [
      ...colDisplay,
      ...[
        {
          title: html` <webmodule-icon library="fa" name="fas-bars"></webmodule-icon>`,
          classes: 'colpxmax-48 item-menu',
          fieldName: 'xx',
          displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
            const rowItem = item as QuoteItem;

            return this.ellipsisMenu(rowItem);
          }
        }
      ]
    ];
  }

  public async refreshData() {
    await this.quoteManager.needsQuoteItems(false);
    await this.quoteManager.needsQuoteSupport(true);
    await this.quoteManager.performItemSorting();

    if (this.quoteManager.container.items) {
      this._data = [...this.quoteManager.container.items];
    } else {
      this._data = [];
    }
    this.requestUpdate();
  }

  @monitor('_expandedOptions', { delayUntilFirstUpdate: true })
  public async handleExpandCollapse() {
    if (!this.table) return;

    this.quoteManager.container.items?.forEach(x => {
      const rowItem = x;

      const isShown = this.table!.isExpanded(rowItem);

      if (this._expandedOptions && !isShown)
        this.table!.addExtension(rowItem, this._extendedDetailsTemplateGenerator(rowItem.id));
      else if (!this._expandedOptions && isShown) this.table!.remExtension(rowItem);
    });

    const indicator = document.querySelector('#quote-item-expand-all-indicator')! as WebmoduleIcon;

    if (this._expandedOptions) indicator.name = 'fas-angles-up';
    else indicator.name = 'fas-angles-down';
  }

  getExtendedDetails(quoteItemContainer: QuoteItemContainer, _class?: 'even' | 'odd') {
    if (isFrame(quoteItemContainer.item)) return this.getFrameExtendedDetails(quoteItemContainer, _class);
    else if (isSpecialItem(quoteItemContainer.item)) return this.getSpecialItemExtendedDetails(quoteItemContainer);

    return this.getOtherLineItemDetails(quoteItemContainer);
  }

  quoteCalculationsEnabled() {
    return this.quoteManager.hasQuoteCalculationData;
  }

  getQuoteItemTags(quoteItemContainer: QuoteItemContainer) {
    const validationIndicator = () => {
      const state = this.getItemValidationType(quoteItemContainer);
      switch (state) {
        case FrameValidationType.note:
          return html` <webmodule-icon library="fa" name="fas-file-lines" class="text-blue"></webmodule-icon>`;
        case FrameValidationType.information:
          return html` <webmodule-icon library="fa" name="fas-circle-info" class="text-cyan"></webmodule-icon>`;
        case FrameValidationType.warning:
          return html` <webmodule-icon
            library="fa"
            name="fas-triangle-exclamation"
            class="text-yellow"
          ></webmodule-icon>`;
        case FrameValidationType.critical:
          return html` <webmodule-icon library="fa" name="fas-circle-exclamation" class="text-red"></webmodule-icon>`;
        case FrameValidationType.outOfDate:
          return html` <webmodule-icon library="fa" name="fas-wrench" class="text-red"></webmodule-icon>`;
      }
      if (!quoteItemContainer.price.isTaxableItem) {
        return html` <webmodule-icon library="default" name="no-tax" class="text-base"></webmodule-icon>`;
      }
      return html``;
    };
    const notesIndicator = () => {
      if (quoteItemContainer.item.comment)
        return html` <webmodule-icon library="fa" name="fas-message" class="text-green"></webmodule-icon>`;
      else return html``;
    };

    const supplierPriceAdjIndicator = () => {
      if (quoteItemContainer.price.supplierPriceAdjustment != 0)
        return html` <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>`;
      else return html``;
    };

    const dealerPriceAdjIndicator = () => {
      if (quoteItemContainer.price.priceAdjustment !== 0)
        return html` <webmodule-icon library="fa" name="fas-tags" class="text-blue"></webmodule-icon>`;
      else return html``;
    };

    return html`${supplierPriceAdjIndicator()}${dealerPriceAdjIndicator()}${notesIndicator()}${validationIndicator()}`;
  }

  generateQuoteItemOptionsView(quoteItemContainer: QuoteItemContainer, childVisible: boolean) {
    if (childVisible)
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"><i class="fa-solid fa-caret-up"></i></span>`;
    else
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"><i class="fa-solid fa-caret-down"></i></span>`;
  }

  getItemValidationType(quoteItemContainer: QuoteItemContainer): FrameValidationType {
    //we are currently only supporting items that are for v6
    if (!isV6(quoteItemContainer)) return FrameValidationType.nothing;

    const data = getV6FrameForContainer(quoteItemContainer);
    if (!data) return FrameValidationType.nothing;

    if (this.quoteManager.currentStateRequiresValidation) {
      if (this.quoteManager.isFrameOutOfDate(quoteItemContainer, [], true)) return FrameValidationType.outOfDate;
    }
    // no validations on the frame, no point trying to find the highest ranked
    if (data.validations?.length == 0) {
      return FrameValidationType.nothing;
    }

    return rankToValidationType(highestValidationRank(data.validations));
  }

  getOptionsOneLiner(quoteItemContainer: QuoteItemContainer) {
    //We should only progress if item is a configuration from supplier.
    if (!isV6(quoteItemContainer)) return '';
    //We are only here if we are a v6 Frame
    const data = getV6QuoteItemForContainer(quoteItemContainer);
    return getV6QuoteItemFrameDimensions(data);
  }

  ellipsisMenu(quoteItem: QuoteItem) {
    const clickOpen = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.openItem(quoteItem);
    };

    const clickCopy = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.copyItem(quoteItem);
    };

    const clickDelete = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.deleteItem(quoteItem);
    };

    const clickProperties = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.propertiesItem(quoteItem);
    };
    const clickBomViewer = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.showBomView(quoteItem);
    };

    const canShowBom =
      (isCynclyStaff() || isSupplierAgent() || isDebugMode()) &&
      v6SupportsVersion(v6VersionMap.hasBomView) &&
      isFrame(quoteItem);

    return html` <webmodule-dropdown class="item-menu text-start" placement="bottom-end" hoist>
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
          canShowBom,
          () =>
            html` <webmodule-menu-item @click=${clickBomViewer}>
                <webmodule-icon slot="prefix" library="fa" name="fas-list-check"></webmodule-icon>
                ${tlang`Bom View`}
              </webmodule-menu-item>
              <webmodule-divider></webmodule-divider>`
        )}
        ${when(
          this.quoteManager.isReadonly() || isShipping(quoteItem) || isSSI(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickDelete}>
              <webmodule-icon slot="prefix" library="fa" name="fas-trash"></webmodule-icon>
              ${tlang`Delete`}
            </webmodule-menu-item>`
        )}
        ${when(
          this.quoteManager.isReadonly() ||
            isAnyFreehand(quoteItem) ||
            isShipping(quoteItem) ||
            isSpecialItem(quoteItem) ||
            isSSI(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickCopy}>
              <webmodule-icon slot="prefix" library="fa" name="fas-copy"></webmodule-icon>
              ${tlang`Copy`}
            </webmodule-menu-item>`
        )}
        ${when(
          isSSI(quoteItem) || isDealerQuotePriceAdjustment(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickOpen}>
              <webmodule-icon slot="prefix" library="fa" name="fas-pen-to-square"></webmodule-icon>
              ${tlang`Edit`}
            </webmodule-menu-item>`
        )}

        <webmodule-menu-item @click=${clickProperties}>
          <webmodule-icon slot="prefix" library="fa" name="fas-sliders"></webmodule-icon>
          ${tlang`Pricing`}
        </webmodule-menu-item>
      </webmodule-menu>
    </webmodule-dropdown>`;
  }

  async showBomView(quoteItem: QuoteItem) {
    const container = this.quoteManager.quoteItemContainer(quoteItem.id);
    const v6Data = getV6QuoteItemForContainer(container);
    const url = this.quoteApi.api.fullUrl(`api/file/${quoteItem.virtualThumbnailPath}`);
    const quoteTitle = getQuoteTitleStr(this.quoteManager.quote);
    const itemTitle = quoteItem.description;
    const itemPos = this.quoteManager.itemPosition(container.item.id);
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const title = `${quoteTitle} - #${itemPos} ${itemTitle}`;
    this.quoteManager.quote;
    const viewer = await constructAsync(
      new V6BomViewer(title, {
        id: quoteItem.id,
        quantity: quoteItem.quantity,
        quoteItem: v6Data,
        thumbnailURL: url
      })
    );

    await viewer.showModal();
  }

  _extendedDetailsTemplateGenerator = id => () => this.getExtendedDetails(this.quoteManager.quoteItemContainer(id));

  protected async firstUpdated(_changedProperties: PropertyValues) {
    await this.refreshData();
    this._columnsData = this.getColumns();
    this.updateCols();
  }

  protected render() {
    const keyEvent = (item: QuoteItem) => {
      return item.id;
    };

    const rowIdEvent = (item: QuoteItem) => {
      return item.id;
    };

    const itemRowClassEvent = (item: QuoteItem) => {
      if (!isSpecialItem(item)) return '';
      const bqs = this.quoteManager.branchQuoteSupport;
      const link = bqs.conversationLinks.find(x => x.quoteItemId === item.id);
      if (!link) return '';
      const support = bqs.items.find(x => x.conversationId === link.conversationId);
      if (!support) return '';

      return `quotesupport-status-${BranchQuoteSupportStatus[support.status].toLowerCase()}`;
    };

    const enableDragEvent = (item: QuoteItem): boolean => {
      return !(item && (isSSI(item) || isShipping(item)));
    };

    return html` <webmodule-lit-table
      id="quote-items-table"
      class="quote-items-table"
      .rowClass=${'tr'}
      .colClass=${'column'}
      .keyevent=${keyEvent}
      .itemRowClassEvent=${itemRowClassEvent}
      .rowidevent=${rowIdEvent}
      .checkEnabledDragEvent=${enableDragEvent}
      tablestyle="nestedtable"
      .columns=${this._columns}
      pageLength="100"
      .data=${this._data}
      .clickrows=${false}
      @webmodule-table-item-move=${this._handleMove}
      .enableDrag=${!this.quoteManager.isReadonly() ? 'true' : undefined}
    >
    </webmodule-lit-table>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected getNotesTemplate(quoteItemContainer: QuoteItemContainer) {
    if (!isEmptyOrSpace(quoteItemContainer.item.comment)) {
      return html` <ul class="list-group">
        <li class="list-group-item list-group-item-primary">
          <span class="me-3 fw-bold">Notes: </span>
          ${quoteItemContainer.item.comment}
        </li>
      </ul>`;
    }
    return html``;
  }

  private async _handleMove(e: CustomEvent<WebModuleTableItemMove>) {
    const newData = this.moveArrayItem([...this._data], e.detail).map(x => x.commonId);

    if (await this.quoteManager.updateItemSortOrder(newData)) {
      await this.requestUpdate();
    }
  }

  private moveArrayItem<T>(array: T[], moveData: WebModuleTableItemMove): T[] {
    const { sourceIndex, targetIndex } = moveData;

    // Ensure the indices are within the bounds of the array
    if (
      sourceIndex < 0 ||
      sourceIndex >= array.length ||
      targetIndex < 0 ||
      targetIndex >= array.length ||
      sourceIndex === targetIndex
    ) {
      throw new Error('Invalid indices provided');
    }

    // Remove the item from the original position
    const item = array.splice(sourceIndex, 1)[0];

    // Insert the item at the new position
    array.splice(targetIndex, 0, item);

    return array;
  }

  private async openItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.edit
    });
  }

  private async copyItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.copy
    });
  }

  private async deleteItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.delete
    });
  }

  private async propertiesItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.properties
    });
  }

  private getColumns(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];

    const addColumnDef = (columnId: string, column: WebModuleLitTableColumnDef) => {
      cols.push({ ...column, id: columnId, sortable: false });
    };

    addColumnDef('number', {
      title: tlang`Item #`,
      classes: 'colpxmax-64 quote-item-no',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, index: number) => {
        const rowItem = item as QuoteItem;

        if (rowItem.isRestrictedToPowerUser) return html`${index + 1}. <span class="power-user"></span>`;
        return html`${index + 1}`;
      }
    });
    addColumnDef('thumbnail', {
      title: tlang`Image`,
      classes: 'colpxmax-64 quote-item-image',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const row = item as QuoteItem;

        const src =
          row.virtualThumbnailPath === ''
            ? quoteItemThumbSrc(row)
            : this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`);

        const imagePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();

          if (isFrame(row) || !isEmptyOrSpace(row.virtualThumbnailPath))
            this.imagePreview?.showImagePreview(
              this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`),
              'quote-item-thumb'
            );
        };

        const hidePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          this.imagePreview?.hideImagePreview();
        };

        return html` <div
          @mouseenter=${imagePreview}
          @mouseleave=${hidePreview}
          @touchstart=${imagePreview}
          @touchend=${hidePreview}
          @touchcancel=${hidePreview}
        >
          <img
            class="quote-item-thumbnail"
            alt="Thumbnail for quote item"
            src="${src}"
            style="width:48px; height:48px"
          />
        </div>`;
      }
    });
    addColumnDef('location', {
      title: tlang`%%frame-title%% and Description`,
      classes: 'colpx-240 quote-item-title',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const clickEvent = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();

          await this.openItem(rowItem);
        };

        const title = isEmptyOrSpace(rowItem.title) ? tlang`%%frame-title%%` : rowItem.title;

        return html`<a class="quote-item-link" href="#" @click="${clickEvent}"
            >${firstValidString(title, tlang`Untitled`)}</a
          >
          <span class="quote-item-description">${rowItem.description}</span>`;
      }
    });

    const expandButton = html`
      <span>Options</span>
      <div>
        <webmodule-icon
          id="quote-item-expand-all-indicator"
          library="fa"
          name="fas-angles-down"
          @click=${() => (this._expandedOptions = !this._expandedOptions)}
        ></webmodule-icon>
      </div>
    `;

    addColumnDef('options', {
      title: html`${expandButton}`,
      classes: 'colpx-160 quote-item-description',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        try {
          const container = this.quoteManager.quoteItemContainer(rowItem.id);

          return this.generateQuoteItemOptionsView(container, _table.isExpanded(rowItem) != undefined);
        } catch {
          return html``;
        }
      },
      click: (e: Event, table: WebModuleLitTable, item: unknown) => {
        e.stopPropagation();
        e.preventDefault();

        const rowItem = item as QuoteItem;

        const isShown = table.isExpanded(rowItem);

        if (isShown) table.remExtension(rowItem);
        else {
          table.addExtension(rowItem, this._extendedDetailsTemplateGenerator(rowItem.id));
        }

        return true;
      }
    });

    const tooltipIcons = html` <webmodule-tooltip placement="right" class="table-header-tooltip" hoist>
      <div slot="content">
        <div>
          <webmodule-icon library="fa" name="fas-message" class="text-green"></webmodule-icon>
          <span>${tlang`Note`}</span>
        </div>
        <small>Frame Configuration</small>
        <webmodule-divider></webmodule-divider>
        <div>
          <webmodule-icon library="fa" name="fas-circle-info" class="text-cyan"></webmodule-icon>
          <span>${tlang`Information`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-file-lines" class="text-blue"></webmodule-icon>
          <span>${tlang`Supplier Note`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-triangle-exclamation" class="text-yellow"></webmodule-icon>
          <span>${tlang`Warning`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-circle-exclamation" class="text-red"></webmodule-icon>
          <span>${tlang`Critical Error`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-wrench" class="text-red"></webmodule-icon>
          <span>${tlang`Require Validation`}</span>
        </div>

        <small>Pricing</small>
        <webmodule-divider></webmodule-divider>
        <div>
          <webmodule-icon library="fa" name="fas-tags" class="text-blue"></webmodule-icon>
          <span>${tlang`Dealer Price Adjustment`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>
          <span>${tlang`Supplier Price Adjustment`}</span>
        </div>
        <div>
          <webmodule-icon library="default" name="no-tax" class="text-base"></webmodule-icon>
          <span>${tlang`No Tax`}</span>
        </div>
      </div>

      <span>${tlang`Tags`} <webmodule-icon library="fa" name="far-circle-question"></webmodule-icon></span>
    </webmodule-tooltip>`;

    addColumnDef('tags', {
      title: tooltipIcons,
      classes: 'colpxmax-64 quote-item-tags no-pseudo',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        try {
          const container = this.quoteManager.quoteItemContainer(rowItem.id);

          return this.getQuoteItemTags(container);
        } catch {
          return html``;
        }
      }
    });
    addColumnDef('qty', {
      title: tlang`Qty`,
      classes: 'colpxmax-48 quote-item-quantity',
      fieldName: 'quantity'
    });
    addColumnDef('lastModified', {
      title: tlang`Last Modified`,
      classes: 'colpxmax-100 quote-item-modified no-pseudo',
      fieldName: 'lastModifiedDate',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const dt = new Date(rowItem.lastModifiedDate!);
        return html`${dt.toLocaleDateString()} <br />
          ${dt.toLocaleTimeString()}`;
      }
    });

    if (this.quoteCalculationsEnabled()) {
      const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
      const qcdata = data.quoteCalculations as QCQuoteValues;
      const colDefs: QCValue[] = [];
      if (qcdata) {
        qcdata.quoteItemValues.forEach(x => {
          x.values.forEach(col => {
            if (col.displayColumn && colDefs.find(x1 => x1.name == col.name) === undefined) colDefs.push(col);
          });
        });
        for (let iQCV = 0; iQCV < colDefs.length; iQCV++) {
          const qcCol = colDefs[iQCV];
          addColumnDef(qcCol.name, {
            title: lang(firstValidString(qcCol.caption, qcCol.name)),
            classes: 'colpxmax-98 alignment-right quote-calc-col',
            fieldName: 'xx',
            displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
              const rowItem = item as QuoteItem;

              let colValue = '';
              if (isFrame(rowItem)) {
                const qcVal = qcdata.quoteItemValues
                  .find(x => x.quoteItemId === rowItem.id)
                  ?.values.find(val => val.name == qcCol.name);

                if (qcVal) colValue = firstValidString(qcVal.displayValue, tryAsNumberString(qcVal.value, 2));
              }
              return html`${colValue}`;
            }
          });
        }
      }
    }

    addColumnDef('extendedListPrice', {
      title: tlang`Ext. List Price`,
      classes: 'colpxmax-110 alignment-right quote-item-price-sup-ext-list supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isAnyFreehand(rowItem)) return '';

        const value = this.quoteManager.getQuoteItemExtendedListPrice(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemExtendedListPriceTotal())
    });
    addColumnDef('multiplier', {
      title: tlang`Multi.`,
      classes: 'colpxmax-60 quote-item-price-sup-multiplier supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isAnyFreehand(rowItem)) return '';

        const multiplier = this.quoteManager.getQuoteItemMultiplier(rowItem.id);
        return number4dpToHtml(multiplier);
      }
    });
    addColumnDef('priceAdjustmentSupplier', {
      title: tlang`Supplier Price Adj.`,
      classes: 'colpxmax-130 alignment-right quote-item-price-sup-adj supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isAnyFreehand(rowItem)) return '';

        const value = this.quoteManager.getQuoteItemSupplierPriceAdjustment(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemSupplierPriceAdjustmentTotal())
    });
    addColumnDef('dealerCost', {
      title: tlang`Cost Price`,
      classes: 'colpxmax-110 alignment-right quote-item-price-del-cost dealer-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const value = this.quoteManager.getQuoteItemDealerCost(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemDealerCostTotal())
    });

    addColumnDef('margin', {
      title: `Pricing Metric (%)`,
      classes: 'colpxmax-130 quote-item-price-del-margin dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isDealerQuotePriceAdjustment(rowItem)) return '';

        const value = this.quoteManager.getQuoteItemMarginOrMarkupPercent(rowItem.id);
        return html`${number2dpToHtml(value)}%`;
      }
    });

    addColumnDef('priceAdjustment', {
      title: tlang`Dealer Price Adj.`,
      classes: 'colpxmax-120 alignment-right quote-item-price-del-adj dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isDealerQuotePriceAdjustment(rowItem)) return '';
        const value = this.quoteManager.getQuoteItemDealerPriceAdjustment(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemDealerPriceAdjustmentTotal())
    });
    addColumnDef('tax', {
      title: tlang`Tax`,
      classes: 'colpxmax-110 alignment-right quote-item-tax dealer-tax-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem) => {
        const price = this.quoteManager.quoteItemTax(rowItem.id);
        return price === 0 ? '' : moneyToTemplateResult(price);
      },
      columnAggregate: () => {
        return moneyToTemplateResult(this.quoteManager.quotePrice.calculatedTaxAmount);
      }
    });

    addColumnDef('price', {
      title: tlang` Selling Price`,
      classes: 'colpxmax-110 alignment-right quote-item-price dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const price = this.quoteManager.quoteItemPrice(rowItem.id, _table.isExpanded(rowItem) != undefined);
        return moneyToTemplateResult(price);
      },
      columnAggregate: () => {
        return moneyToTemplateResult(this.quoteManager.quoteItemPriceTotal);
      }
    });

    return cols;
  }

  private getBuyInValidation(quoteItemContainer: QuoteItemContainer) {
    return this.quoteManager.getPriceValidation(quoteItemContainer.item.id);
  }

  private getSpecialItemExtendedDetails(quoteItemContainer: QuoteItemContainer) {
    const warningTemplate = (): TemplateResult => {
      if (!isEmptyOrSpace(quoteItemContainer.item.description)) return html``;

      return html`
        <ul class="list-group">
          <li class="list-group-item list-group-item-warning">
            <span class="me-3 fw-bold">${tlang`%%supplier%% Reference`}</span>
            ${tlang`The %%supplier%% must provide a reference`}
          </li>
        </ul>
      `;
    };

    return html` <div class="extended-options-line">
      ${warningTemplate()} ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;
  }

  private getOtherLineItemDetails(quoteItemContainer: QuoteItemContainer) {
    if (
      !(
        (isShipping(quoteItemContainer.item) ||
          isDefaultShipping(quoteItemContainer.item) ||
          isAnyFreehand(quoteItemContainer.item)) &&
        !isEmptyOrSpace(quoteItemContainer.item.comment)
      )
    )
      return html``;

    return html` <div class="extended-options-line">${this.getNotesTemplate(quoteItemContainer)}</div>`;
  }

  private getFrameExtendedDetails(quoteItemContainer: QuoteItemContainer, classMap?: 'even' | 'odd'): TemplateResult {
    //only here if we are a frame and also a v6 frame
    if (!isV6(quoteItemContainer)) return html``;
    if (!isValidV6ConfigVersion()) {
      const message = tlang`%%supplier%% is OFFLINE`;
      return html`${message}`;
    }

    const data = getV6FrameForContainer(quoteItemContainer);

    const validationsTemplate = (
      validationType: FrameValidationType,
      cssClass: string
    ): TemplateResult[] | TemplateResult => {
      const stuff = data?.validations?.filter(x => x.type === validationType) ?? [];
      if (stuff.length === 0) return html``;
      const rows = stuff.map(issue => {
        return html` <li class="list-group-item ${cssClass}">
          <span class="me-3 fw-bold">${issue.title}</span>${lang(issue.text ?? '')}
        </li>`;
      });
      return html`
        <ul class="list-group">
          ${rows}
        </ul>
      `;
    };

    const validationTemplates = html` <div class="extended-options-line">
      ${data ? getFrameDetailsTemplate(data, true) : undefined}
      ${validationsTemplate(FrameValidationType.critical, 'list-group-item-danger')}
      ${validationsTemplate(FrameValidationType.warning, 'list-group-item-warning')}
      ${validationsTemplate(FrameValidationType.information, 'list-group-item-info')}
      ${validationsTemplate(FrameValidationType.note, 'list-group-item-note')}
      ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;

    const getBuyInRow = (buyInItem: FrameBuyInItem, heightFirst: null | boolean) => {
      const buyIn = this.quoteManager.lookupBuyInData(
        quoteItemContainer,
        buyInItem.code,
        buyInItem.extraDetails?.['SuppCode'] ?? '',
        `Length=${buyInItem.extraDetails?.['Length'] ?? ''};Width=${buyInItem.extraDetails?.['Width'] ?? ''};Height=${buyInItem.extraDetails?.['Height'] ?? ''}`
      );

      const codeDisplay = () => {
        return html`(${buyInItem.extraDetails?.['SuppCode'] ?? ''} - ${buyInItem.code})`;
      };

      const priceValidations = () => {
        const validations = this.getBuyInValidation(quoteItemContainer);

        if (validations?.details && validations.details.length > 0)
          return validations.details.map(x => html`<br /><span class="text-danger">${x.message}</span>`);

        return html``;
      };

      const titleDisplay = (description: string | undefined) => {
        return html`${description ?? ''} ${codeDisplay()} ${priceValidations()}`;
      };
      const buyInValueTemplate = buyIn?.calculatedGross ? moneyToTemplateResult(buyIn.calculatedGross) : '';
      return html` <div class="extended-options-line">
        <div class="tr ${classMap}">
          <div class="column colpxmax-64 quote-item-no"></div>
          <div class="column colpxmax-64 quote-item-image">
            ${supplierItemContentTypeDisplay(buyInItem.resourceType, false)}
          </div>
          <div class="column colpx-240 quote-item-title">${titleDisplay(buyIn?.description)}</div>
          <div class="column colpx-160 quote-item-description">${getBuyInDetailsTemplate(buyInItem, heightFirst)}</div>
          <div class="column colpxmax-48 quote-item-quantity">${buyInItem.quantity}</div>
          <div class="column colpxmax-160 quote-item-modified"></div>

          <div class="column colpxmax-120 alignment-right quote-item-price">${buyInValueTemplate}</div>
          <div class="column colpxmax-48 item-menu"></div>
        </div>
      </div>`;
    };

    const quoteItemData = getV6QuoteItemForContainer(quoteItemContainer);
    const heightFirst = getV6QuoteItemDisplayHeightFirst(quoteItemData);

    return html`${data?.buyIn?.map((item, _index) => getBuyInRow(item, heightFirst))} ${validationTemplates}`;
  }
}

@customElement('wm-franchiseequoteitemsview')
export class FranchiseeQuoteItemsView extends QuoteItemsView {
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  quoteApi: QuoteApi = getApiFactory().quote();
  supplierApi = getApiFactory().supplier();
  franchiseeApi = getApiFactory().franchisee();
  @query('#franchisee-quote-item-table')
  table?: FranchiseeQuoteItemTable;
  @state()
  summaryData?: QuotePriceSummaryDataContainer;

  constructor(options: QuoteItemsViewOptions) {
    super(options);

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `quoteprice-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.summaryData),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('termsAndConditions', FieldType.string, false, undefined, () => this.quoteManager.quote);

    addQuotePriceSummaryToDataTracker(() => this.summaryData, this.dataTracker);

    addField('budget', FieldType.money, true, undefined, () => this.quoteManager.quote);
  }

  protected get qcm(): FranchiseeQuoteContainerManager {
    return this.quoteManager as FranchiseeQuoteContainerManager;
  }

  refreshSummaryData() {
    this.summaryData = convertQuoteToQuotePriceSummaryDataContainer(
      this.quoteManager as FranchiseeQuoteContainerManager,
      { mergeTaxFields: true }
    );
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.

    await this.table?.refreshData();
    this.requestUpdate();
  }

  quoteCalculationsEnabled() {
    return (this.quoteManager as FranchiseeQuoteContainerManager).hasQuoteCalculationData;
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();

    const systemDefaults = await this.franchiseeApi.getSystemConfiguration();
    const supplierOverrides = await this.supplierApi.getSupplierQuoteConfig({
      supplierId: this.quoteManager.quote.supplierId
    });

    const v6Columns: { code: string; title: string }[] = [];

    if (this.quoteCalculationsEnabled()) {
      const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
      const qcdata = data.quoteCalculations as QCQuoteValues;
      const colDefs: QCValue[] = [];
      if (qcdata) {
        qcdata.quoteItemValues.forEach(x => {
          x.values.forEach(col => {
            if (col.displayColumn && colDefs.find(x1 => x1.name == col.name) === undefined) colDefs.push(col);
          });
        });
        for (let iQCV = 0; iQCV < colDefs.length; iQCV++) {
          const qcCol = colDefs[iQCV];
          v6Columns.push({ code: qcCol.name, title: lang(firstValidString(qcCol.caption, qcCol.name)) });
        }
      }
    }

    //Check system V6 columns
    if (
      v6Columns &&
      v6Columns.length > 0 &&
      systemDefaults?.defaultDealerConfiguration?.quoteItemColumnConfigurations
    ) {
      const v6ColConfig: TableColumnConfiguration[] = [];

      v6Columns.map(x =>
        v6ColConfig.push({
          code: x.code,
          title: x.title,
          systemDefault: false,
          defaultDisplay: true,
          canSwitch: true
        })
      );

      const insertIndex = systemDefaults.defaultDealerConfiguration.quoteItemColumnConfigurations.length - 7;

      systemDefaults.defaultDealerConfiguration.quoteItemColumnConfigurations.splice(insertIndex, 0, ...v6ColConfig);
    }

    this.settingsManager = new SettingsManager(
      systemDefaults?.defaultDealerConfiguration?.quoteItemColumnConfigurations ?? [],
      supplierOverrides?.quoteItemSummaryConfigurations ?? []
    );
  }

  public async invalidate(): Promise<void> {
    super.invalidate();
    await this.qcm.updatePricingRules();
  }

  public async prepareForSave() {
    await this.quoteManager.needsQuoteItems();
    this.dataTracker.applyChangeToValue();
  }

  getTaxLabel(): string | undefined {
    const rate = userDataStore.taxRates.find(taxRate => taxRate.rate == this.quoteManager.quotePrice.taxPercentage);

    if (rate) {
      return tlang`${rate.name} ${rate.rate}%`;
    }

    return tlang`%%tax%% (${this.quoteManager.quotePrice.taxPercentage.toFixed(2)}%)`;
  }

  getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (this.quoteManager.quoteState == QuoteState.Active || this.quoteManager.quoteState == QuoteState.Draft)
      return errors;

    if (this.quoteManager.quoteState == QuoteState.Cancelled) {
      return [];
    }

    if (
      this.quoteManager.container.items &&
      this.quoteManager.container.items.filter(x => !isShipping(x)).length == 0
    ) {
      errors.push(
        tlang`There are no !!quote-item!! in this %%quote%%. A blank %%quote%% will not be issued to the %%client%%.`
      );
    }

    return errors;
  }

  public eventRunPriceAdjustmentModal = async () => {
    if (
      !(await QuoteDealerPriceAdjustmentDialog.Execute(
        this.quoteManager as FranchiseeQuoteContainerManager,
        this.isClientFacing
      ))
    )
      return;

    await this.table?.refreshData();
    this.requestUpdate();

    return;
  };

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
    this.refreshSummaryData();

    const quoteCalculationsTemplate = awaitableTemplate(async (): Promise<TemplateResult[]> => {
      await this.quoteManager.needsQuoteItems();

      const qcResultTemplate = (item: QCValue) => html`
        <div class="quotecalculation-col">
          <div class="quotecalculation-col-name">${item.name}</div>
          <div class="quotecalculation-col-value">
            ${firstValidString(item.displayValue, tryAsNumberString(item.value, 2))}
          </div>
        </div>
      `;

      const sections: TemplateResult[] = [];
      if (this.qcm.hasQuoteCalculationData) {
        const data = this.qcm.quoteProviderData() as V6FranchiseeQuoteProviderData;
        const qcv = data.quoteCalculations as QCQuoteValues;
        if (qcv) {
          qcv.quoteValues.forEach(element => {
            sections.push(
              html` <div class="quotecalculation-section">
                <div class="quotecalculation-label">${lang(element.title)}</div>
                <div class="quotecalculation-result">${element.values.map(x => qcResultTemplate(x))}</div>
              </div>`
            );
          });
        }
      }
      return sections;
    });

    const dealerName = userDataStore.franchisee.name;
    const termsAndConditions = isEmptyOrSpace(dealerName)
      ? tlang`Dealer Terms and Conditions`
      : tlang`${dealerName} Terms and Conditions`;

    const actionQuoteItem = async (e: CustomEvent) => {
      const quoteItem = e.detail.item as QuoteItem;
      const action = e.detail.quoteAction;

      const qicm = this.quoteManager.quoteItemContainer(quoteItem.id);
      await this.eventRunQuoteItemActions?.(qicm, action);
    };

    const getMenuItems = () => {
      return this.settingsManager?.getActiveConfiguration
        .filter(x => x.canSwitch)
        .map(
          mi =>
            html` <webmodule-menu-item
              .value=${mi.code}
              type="checkbox"
              checked=${mi.defaultDisplay ? true : nothing}
              disabled=${!mi.canSwitch ? true : nothing}
              >${mi.title}
            </webmodule-menu-item>`
        );
    };
    const itemsSubtotalTemplate = this.quoteManager.allowNonTaxableItems
      ? html`${forms.moneyReadonly('nonTaxableSubtotal', tlang`Zero Rated Items`)}
        ${forms.moneyReadonly('taxableSubtotal', tlang`Taxed Items`)}`
      : forms.moneyReadonly('taxableSubtotal', tlang`Items`);
    const priceAdjustmentsButtonTemplate = forms.clickableIcon({
      slot: 'suffix',
      library: 'fa',
      name: 'fas-sliders',
      class: 'text-primary  mb-2 ms-3',
      events: { click: this.eventRunPriceAdjustmentModal }
    });
    const taxTemplate = this.quoteManager.isReadonly()
      ? forms.moneyReadonly('calculatedTaxAmount', this.getTaxLabel())
      : forms.moneyReadonly('calculatedTaxAmount', this.getTaxLabel());

    const quoteItemSummaryTemplate = html`${forms.intReadonly(
        'percentMarginOrMarkup',
        this.qcm.getPricingModelLabel(),
        {
          class: 'mb-3',
          hidden: this.settingsManager?.getIsClientFacing,
          toolTip: this.qcm.getPricingModelTooltip()
        }
      )}
      ${itemsSubtotalTemplate} ${forms.moneyReadonly('priceAdjustment', tlang`Price Adjustment`, {})}
      <hr class="no-margin" />
      ${forms.moneyReadonly('subtotal', tlang`Items Total`, { class: 'mb-3' })}${taxTemplate}
      <hr class="no-margin" />
      ${forms.moneyReadonly('calculatedGrossTotal', tlang`%%quote%% Total`)}`;

    return html`
      <div class="page-configuration-container">
        <h2 class="page-configuration-title">${tlang`!!frame!!`}</h2>
        <webmodule-dropdown hoist placement="bottom-end" stay-open-on-select>
          <webmodule-icon-button
            class="${classMap({
              'text-danger': !this.isClientFacing
            })}"
            slot="trigger"
            name="fas-gear"
            library="fa"
          ></webmodule-icon-button>

          <webmodule-menu @webmodule-select=${(e: WebmoduleSelectEvent) => this._onDisplayChange(e)}>
            <webmodule-menu-label>Page Configuration</webmodule-menu-label>
            <webmodule-menu-item value="internalView"
              >${this.isClientFacing ? tlang`Show Internal View` : tlang`Show Client Facing View`}
            </webmodule-menu-item>
            <webmodule-divider></webmodule-divider>
            <webmodule-menu-label>Table Configuration</webmodule-menu-label>
            ${getMenuItems()}
          </webmodule-menu>
        </webmodule-dropdown>
      </div>

      <franchisee-quote-item-table
        id="franchisee-quote-item-table"
        .columnConfiguration=${this.settingsManager?.getActiveConfiguration ?? []}
        .quoteManager=${this.quoteManager as FranchiseeQuoteContainerManager}
        @wm-quote-item-action=${actionQuoteItem}
      >
      </franchisee-quote-item-table>

      <form class="py-3 px-0 quote-price-summary form-two-col ">
        <div class="row">
          <div class="quote-items-terms">
            <div class="row mb-2 align-items-center form-col-item">
              <webmodule-textarea
                class="label-on-top quote-tnc-block"
                id=${this.dataBinding.field('termsAndConditions')}
                value=${this.dataTracker.getObjectDisplayValue('termsAndConditions') || ''}
                label=${termsAndConditions}
                maxlength="3000"
              ></webmodule-textarea>
            </div>
            ${quoteCalculationsTemplate}
          </div>
          <div class="quote-items-summary">
            <div class="quote-items-summary-wrapper">
              <div class="row">
                <div class="col-10">${quoteItemSummaryTemplate}</div>
                <div class="col-2 align-self-end"><span>${priceAdjustmentsButtonTemplate}</span></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    `;
  }

  private async _onDisplayChange(e: WebmoduleSelectEvent) {
    const item = e.detail.item;

    await saveWithIndicator(async () => {
      if (item.value === 'internalView') {
        this.settingsManager?.toggleClientFacingView();
      } else {
        this.settingsManager?.toggleColumnDisplay(item.value);
      }

      return true;
    });

    this.requestUpdate();
  }
}
