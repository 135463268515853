export function getAssetCdnPath(fileName: string): string {
  if (fileName.startsWith('./assets/')) {
    fileName = fileName.substring(9);
  } else if (fileName.startsWith('assets/')) {
    fileName = fileName.substring(7);
  } else if (fileName.startsWith('./')) {
    fileName = fileName.substring(2);
  } else if (fileName.startsWith('/')) {
    fileName = fileName.substring(1);
  }

  return `https://webmodule-softtech.azureedge.net/v6config/site/assets/${fileName}`;
}
