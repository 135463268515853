import { AppClientPage } from './pages/app-client';
import { AppClientsPage } from './pages/app-clients';
import { AppHome } from './pages/app-home';
import { AppLogin } from './pages/app-login';
import { AppProjectPage } from './pages/app-project';
import { AppProjectsPage } from './pages/app-projects';
import { AppPurchaseOrderPage } from './pages/app-purchase-order';
import { AppPurchaseOrdersPage } from './pages/app-purchase-orders';
import { AppQuotePage } from './pages/app-quote';
import { AppQuotesPage } from './pages/app-quotes';
import { AppSettings } from './pages/app-settings';
import { MenuItem } from '../../webmodule-common/other/app-index';
import { PageNotFound } from './pages/page-not-found';
import { pages } from './page-constants';
import { resourceClient } from './clients/ui/launcher';
import { resourceProject } from '../projects/ui/launcher';
import { resourcePurchaseOrder } from '../purchase-orders/ui/launcher';
import { resourceQuote } from '../quotes/ui/launcher';
import { tlang } from '../../webmodule-common/other/language/lang';
import { validId } from '../../webmodule-common/other/ui/string-helper-functions';

type MenuItemSub = {
  name: string;
  title: string;
};

export function appConfigMenuPageItems() {
  const config = appConfig();
  const getItem = (item: MenuItemSub): MenuItem => {
    return { name: item.name, title: item.title, path: config.routes.find(x => x.name === item.name)?.path ?? '' };
  };
  const pageItems = [
    getItem({
      name: pages.home,
      title: tlang`Dashboard`
    }),
    getItem({
      name: pages.projects,
      title: tlang`!!project!!`
    }),
    getItem({
      name: pages.quotes,
      title: tlang`!!quote!!`
    }),
    getItem({
      name: pages.purchaseOrders,
      title: tlang`!!purchase-order!!`
    }),
    getItem({
      name: pages.clients,
      title: tlang`!!client!!`
    })
  ];
  pageItems.push({
    name: pages.settings,
    title: tlang`Settings`,
    path: '/settings'
  });
  return pageItems;
}

export function appConfig() {
  return {
    routes: [
      {
        path: '/',
        name: pages.home,
        component: 'app-home',
        action: async () => {
          //return home
          return new AppHome(); // await import("./pages/app-home");
        }
      },
      {
        path: '/login',
        name: pages.login,
        component: 'app-login',
        action: async () => {
          return new AppLogin();
        }
      },
      {
        path: '/clients',
        name: pages.clients,

        action: async () => {
          return new AppClientsPage();
        }
      },
      {
        path: '/clients/:id',
        name: pages.client,

        action: async () => {
          return new AppClientPage();
        },
        resolveUrl: {
          type: resourceClient,
          resolver: id => (validId(id) ? `/clients/${id}` : '/clients')
        }
      },
      {
        path: '/settings',
        name: pages.settings,
        action: async () => {
          return new AppSettings();
        }
      },
      {
        path: '/quotes',
        name: pages.quotes,
        action: async () => {
          return new AppQuotesPage();
        }
      },
      {
        path: '/quotes/:id',
        name: pages.quote,
        action: async () => {
          return new AppQuotePage();
        },
        resolveUrl: {
          type: resourceQuote,
          resolver: id => (validId(id) ? `/quotes/${id}` : '/quotes')
        }
      },
      {
        path: '/projects',
        name: pages.projects,
        component: 'app-projects',
        action: async () => {
          return new AppProjectsPage();
        }
      },
      {
        path: '/projects/:id',
        name: pages.project,
        action: async () => {
          return new AppProjectPage();
        },
        resolveUrl: {
          type: resourceProject,
          resolver: id => (validId(id) ? `/projects/${id}` : '/projects')
        }
      },
      {
        path: '/purchase-orders/:id',
        name: pages.purchaseOrder,

        action: async () => {
          return new AppPurchaseOrderPage();
        },
        resolveUrl: {
          type: resourcePurchaseOrder,
          resolver: id => (validId(id) ? `/purchase-orders/${id}` : '/purchase-orders')
        }
      },
      {
        path: '/purchase-orders',
        name: pages.purchaseOrders,

        action: async () => {
          return new AppPurchaseOrdersPage();
        }
      },
      {
        path: '(.*)',
        name: 'not-found',
        action: async () => {
          return new PageNotFound();
        }
      }
    ]
  };
}
