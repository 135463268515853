// eslint-disable-next-line import/named

import {
  ConvertEnumToFormSelectOptionsArray,
  EnumValueToString,
  FormInputSelectValue,
  MapArrayToFormInputSelectValue
} from './form-input-types.js';
import { DataBinding } from '../databinding/databinding.js';
import { DataTracker, EventValue, FieldType } from '../databinding/data-tracker.js';
import { formInput } from './form-input.js';
import { FormInputAssistantBase } from './form-input-assistant-base.js';
import { formRadioGroup } from './form-radio-group.js';
import { formSelect } from './form-select.js';
import { html, nothing, TemplateResult } from 'lit';
import { InputEventMap, InputType, PickerEventMap, WebmoduleFormInputEvent } from './input-type.js';
import { isEmptyOrSpace } from '../string-helper-functions.js';
import { noParalellExecutionAsync } from '../../common/helpers/callbacks';

export interface InputClasses {
  id: string;
  classes: string;
}

export interface DropDownMenuDetails {
  isSeperator?: boolean;
  label?: string;
  icon?: string;
  onClick?: WebmoduleFormInputEvent;
}

export class DropDownMenuDefinition {
  public items: DropDownMenuDetails[];

  constructor(items: DropDownMenuDetails[]) {
    this.items = items;
  }

  templates() {
    return this.items.map(item => {
      return item.isSeperator && item.label
        ? html` <webmodule-menu-item disabled>
            <hr class="dropdown-divider" />
            <p class="fw-bold mb-0">${item.label}</p>
            <hr class="dropdown-divider" />
          </webmodule-menu-item>`
        : item.isSeperator
          ? html` <webmodule-menu-item>
              <hr class="dropdown-divider" />
            </webmodule-menu-item>`
          : html` <webmodule-menu-item @click=${item.onClick}>${item.label}</webmodule-menu-item>`;
    });
  }
}

export type EventPickerResult = (() => PickerResult | undefined) | (() => Promise<PickerResult | undefined>);

export interface PickerResult {
  id: string;
  value: string;
}

export class FormInputAssistant extends FormInputAssistantBase {
  protected _dataTracker: DataTracker | (() => DataTracker);

  get dataTracker(): DataTracker {
    return typeof this._dataTracker === 'function' ? this._dataTracker() : this._dataTracker;
  }

  classes: InputClasses[] = [];
  immediateBindingUpdate = false;

  constructor(
    dataTracker: DataTracker | (() => DataTracker),
    forceReadonly: boolean | (() => boolean) = false,
    immediateBindingUpdate = false
  ) {
    super(forceReadonly);

    this._dataTracker = dataTracker;
    this.immediateBindingUpdate = immediateBindingUpdate;
  }

  get makeImmediate(): boolean {
    const x = this.immediateBindingUpdate;
    this.immediateBindingUpdate = true;
    return x;
  }

  get dataBinding(): DataBinding {
    return this.dataTracker.binder;
  }

  id(name: string): string {
    return `${this.dataTracker.binder.internalId}-${name}`;
  }

  public text(fieldName: string, title?: string, maxLength?: number, events?: InputEventMap): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'text', title, maxLength, events })
      : this.formInput({ fieldName, type: 'text', title, maxLength, events });
  }

  public text1(
    fieldName: string,
    title?: string,
    options?: {
      maxLength?: number;
      toolTip?: string | TemplateResult;
      min?: number;
      max?: number;
      placeholder?: string;
      events?: InputEventMap;
      autocaptitalize?: boolean;
      uppercase?: boolean;
      slot?: string;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, title, type: 'text', ...options })
      : this.formInput({ fieldName, title, type: 'text', ...options });
  }

  public password(
    fieldName: string,
    title?: string,
    options?: {
      maxLength?: number;
      toolTip?: string | TemplateResult;
      min?: number;
      max?: number;
      placeholder?: string;
      events?: InputEventMap;
      autocaptitalize?: boolean;
      uppercase?: boolean;
      slot?: string;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, title, type: 'password', ...options })
      : this.formInput({ fieldName, title, type: 'password', ...options });
  }

  public textRequired(fieldName: string, title?: string, maxLength?: number, events?: InputEventMap): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'text', title, maxLength, events })
      : this.formInputRequired({ fieldName, type: 'text', title, maxLength, events });
  }

  public textHidden(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'hidden', title })
      : this.formInput({ fieldName, type: 'hidden', title });
  }

  public textReadonly(fieldName: string, title?: string, toolTip?: string | TemplateResult): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'text', title, toolTip });
  }

  public checkbox(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'checkbox', title })
      : this.formInput({ fieldName, type: 'checkbox', title });
  }

  public switch(
    fieldName: string,
    title?: string,
    options?: {
      readonly?: boolean;
      class?: string;
      slot?: string;
      slotted?: TemplateResult;
      events?: InputEventMap;
      toolTip?: string | TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly || options?.readonly
      ? this.formInputReadOnly({ fieldName, type: 'switch', title, ...options })
      : this.formInput({ fieldName, type: 'switch', title, ...options });
  }

  public switchReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'switch', title });
  }

  public switchRequired(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'switch', title })
      : this.formInputRequired({ fieldName, type: 'switch', title });
  }

  public radioGroupArray(
    fieldName: string,
    values: FormInputSelectValue[],
    options?: {
      title?: string;
      events?: InputEventMap;
      class?: string;
      slot?: string;
      required?: boolean;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formRadioGroup(fieldName, values, options?.title, { ...options, readOnly: true })
      : this.formRadioGroup(fieldName, values, options?.title, { ...options });
  }

  public radioGroupArrayReadonly(
    fieldName: string,
    values: FormInputSelectValue[],
    options?: { title?: string; events?: InputEventMap; class?: string; slot?: string; required?: boolean }
  ): TemplateResult {
    return this.formRadioGroup(fieldName, values, options?.title, { ...options, readOnly: true });
  }

  public radioGroup<EnumeratedType>(
    fieldName: string,
    values: EnumeratedType,
    title?: string,
    options?: {
      hideZeroValue?: boolean;
      events?: InputEventMap;
    }
  ): TemplateResult {
    let hideValues: number[] | undefined;

    if (options?.hideZeroValue) hideValues = [0];

    return this.forceReadonly
      ? this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptionsArray(values, hideValues), title, {
          readOnly: true
        })
      : this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptionsArray(values, hideValues), title, {
          events: options?.events
        });
  }

  public radioGroupHideValues<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string,
    hideValues?: number[],
    map?: EnumValueToString
  ): TemplateResult {
    return this.forceReadonly
      ? this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptionsArray(options, hideValues, map), title, {
          readOnly: true
        })
      : this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptionsArray(options, hideValues, map), title);
  }

  public noteEx(
    fieldName: string,
    title?: string,
    options?: {
      maxLength?: number;
      toolTip?: string | TemplateResult;
      placeholder?: string;
      class?: string;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'area', title, ...options })
      : this.formInput({ fieldName, type: 'area', title, ...options });
  }

  public note(
    fieldName: string,
    title?: string,
    maxLength?: number,
    toolTip?: string | TemplateResult,
    placeholder?: string
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'area', title, maxLength })
      : this.formInput({ fieldName, type: 'area', title, maxLength, toolTip, placeholder });
  }

  public noteRequired(fieldName: string, title?: string, maxLength?: number): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'area', title, maxLength })
      : this.formInputRequired({ fieldName, type: 'area', title, maxLength });
  }

  public noteReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'area', title });
  }

  public date(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string | TemplateResult,
    min?: number
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type, title, toolTip, min })
      : this.formInput({ fieldName, type, title, toolTip, min });
  }

  public dateRequired(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string | TemplateResult,
    min?: number
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type, title, toolTip, min })
      : this.formInputRequired({ fieldName, type, title, toolTip, min });
  }

  public dateReadonly(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string | TemplateResult,
    min?: number
  ): TemplateResult {
    return this.formInputReadOnly({ fieldName, type, title, toolTip, min });
  }

  public int(
    fieldName: string,
    title?: string,
    options?: {
      min?: number;
      max?: number;
      events?: InputEventMap;
      toolTip?: string | TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, ...options })
      : this.formInput({ fieldName, type: 'number', title, ...options });
  }

  public intRequired(
    fieldName: string,
    title?: string,
    options?: {
      min?: number;
      max?: number;
      hidden?: boolean;
      events?: InputEventMap;
      slotted?: TemplateResult;
      toolTip?: string | TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, ...options })
      : this.formInputRequired({ fieldName, type: 'number', title, ...options });
  }

  public intReadonly(
    fieldName: string,
    title?: string,
    options?: {
      hidden?: boolean;
      events?: InputEventMap;
      slotted?: TemplateResult;
      toolTip?: string | TemplateResult;
      class?: string;
    }
  ): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'number', title, ...options });
  }

  public float(
    fieldName: string,
    title?: string,
    options?: {
      min?: number;
      max?: number;
      events?: InputEventMap;
      toolTip?: string | TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, ...options })
      : this.formInput({ fieldName, type: 'number', title, ...options });
  }

  public floatRequired(fieldName: string, title?: string, min?: number, max?: number): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, min, max })
      : this.formInputRequired({ fieldName, type: 'number', title, min, max });
  }

  public floatReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'number', title });
  }

  public money(
    fieldName: string,
    title?: string,
    options?: {
      toolTip?: string | TemplateResult;
      events?: InputEventMap;
      hidden?: boolean;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({
          fieldName,
          type: 'money',
          title,
          ...options
        })
      : this.formInput({ fieldName, type: 'money', title, ...options });
  }

  public moneyRequired(
    fieldName: string,
    title?: string,
    options?: {
      toolTip?: string | TemplateResult;
      events?: InputEventMap;
      hidden?: boolean;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'money', title, ...options })
      : this.formInputRequired({ fieldName, type: 'money', title, ...options });
  }

  public moneyReadonly(
    fieldName: string,
    title?: string,
    options?: {
      class?: string;
      toolTip?: string | TemplateResult;
      events?: InputEventMap;
      hidden?: boolean;
      slotted?: TemplateResult;
    }
  ): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'money', title, ...options });
  }

  public enumPicker<EnumeratedType>(fieldName: string, values: EnumeratedType, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptionsArray(values, undefined), title)
      : this.formSelect(fieldName, ConvertEnumToFormSelectOptionsArray(values, undefined), { title: title });
  }

  public enumPickerReadonly<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptionsArray(options, undefined), title);
  }

  public enumFilter<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptionsArray(options, [0]), title)
      : this.formSelect(fieldName, ConvertEnumToFormSelectOptionsArray(options, [0]), { title });
  }

  public enumFilterReadonly<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptionsArray(options, [0]), title);
  }

  public arraySelect<ItemType>(
    fieldName: string,
    items: ItemType[],
    convert: (x: ItemType) => FormInputSelectValue,
    options?: {
      title?: string;
      events?: InputEventMap;
      placeholder?: string;
      toolTip?: string | TemplateResult;
      slot?: string;
      slotted?: TemplateResult;
      class?: string;
      readonly?: boolean;
    }
  ): TemplateResult {
    return this.forceReadonly || options?.readonly
      ? this.formSelectReadonly(fieldName, MapArrayToFormInputSelectValue(items, convert), options?.title)
      : this.formSelect(fieldName, MapArrayToFormInputSelectValue(items, convert), { ...options });
  }

  public arraySelectReadonly<ItemType>(
    fieldName: string,
    items: ItemType[],
    convert: (x: ItemType) => FormInputSelectValue,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, MapArrayToFormInputSelectValue(items, convert), title);
  }

  public imagePicker(fieldName: string, title?: string) {
    const changeEvent = () => {
      if (this.immediateBindingUpdate) {
        this.dataTracker.getBinder(fieldName)?.applyChangeToValue();
      }
    };

    return html` <bs-form-image-upload
      data-id=${this.dataBinding.field(fieldName)}
      .value="${this.dataTracker.getObjectValue(fieldName)}"
      data-label=${title}
      @webmodule-change=${changeEvent}
    ></bs-form-image-upload>`;
  }

  /**
   *
   * @param fieldName fieldname of the object to read and write data to
   * @param display
   * @param clickEvent
   * @param title optional title
   * @param slotted add extra buttons into the slots
   * @returns
   */

  public picker(
    fieldName: string,
    display: string,
    clickEvent: EventPickerResult | DropDownMenuDefinition,
    title?: string,
    slotted?: TemplateResult,
    events?: PickerEventMap
  ) {
    return this.forceReadonly
      ? this.formPicker(fieldName, display, clickEvent, title, false, true, slotted, events)
      : this.formPicker(fieldName, display, clickEvent, title, false, false, slotted, events);
  }

  public pickerRequired(fieldName: string, display: string, clickEvent: EventPickerResult, title?: string) {
    return this.forceReadonly
      ? this.formPicker(fieldName, display, clickEvent, title, true, true)
      : this.formPicker(fieldName, display, clickEvent, title, true);
  }

  public pickerReadonly(fieldName: string, display: string, title?: string) {
    return this.formPicker(
      fieldName,
      display,
      () => {
        return undefined;
      },
      title,
      false,
      true
    );
  }

  getClasses(fieldName: string) {
    return this.classes
      .filter(x => x.id === '*' || x.id === fieldName)
      .map(x => x.classes)
      .join(' ');
  }

  public updatePicker(fieldName: string, newValue: PickerResult) {
    const displayFieldName = this.dataBinding.field(`${fieldName}-displaycontainer`);
    this.dataBinding.setValue(fieldName, newValue.id);
    const elem = this.dataBinding.parent.querySelector('#' + displayFieldName) as unknown;
    const elemId = this.dataBinding.parent.querySelector('#' + this.dataBinding.field(fieldName)) as unknown;
    if (this.immediateBindingUpdate) this.dataTracker.getBinder(fieldName)?.applyChangeToValue();
    //this is an Input
    if (elemId) (elemId as any).value = newValue.id;
    //this is a WebModuleInput
    if (elem) (elem as any).value = newValue.value;
  }

  private formInputRequired(options: {
    fieldName: string;
    type?: InputType;
    title?: string;
    maxLength?: number;
    decimalPlaces?: number;
    toolTip?: string | TemplateResult;
    min?: number;
    max?: number;
    hidden?: boolean;
    events?: InputEventMap;
    slotted?: TemplateResult;
  }): TemplateResult {
    options.type = options.hidden ? 'hidden' : (options.type ?? 'text');
    options.type = options.type ?? 'text';
    return formInput({
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker,
      readOnly: false,
      ...options,
      immediateBindingUpdate: this.immediateBindingUpdate,
      required: true
    });
  }

  private formInputReadOnly(options: {
    fieldName: string;
    type?: InputType;
    title?: string | undefined;
    maxLength?: number;
    decimalPlaces?: number;
    toolTip?: string | TemplateResult;
    min?: number;
    max?: number;
    class?: string;
    hidden?: boolean;
    events?: InputEventMap;
  }): TemplateResult {
    options.type = options.hidden ? 'hidden' : (options.type ?? 'text');

    return formInput({
      ...options,
      readOnly: true,
      required: false,
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker
    });
  }

  private formInput(options: {
    fieldName: string;
    type?: InputType;
    title?: string;
    maxLength?: number;
    toolTip?: string | TemplateResult;
    min?: number;
    max?: number;
    placeholder?: string;
    events?: InputEventMap;
    autocaptitalize?: boolean;

    class?: string;
  }): TemplateResult {
    const classes = this.getClasses(options.fieldName);
    options.class = `${classes ?? ''} ${options.class ?? ''}`.trim();
    if (isEmptyOrSpace(options.class)) {
      options.class = undefined;
    }
    //
    return formInput({
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker,
      readOnly: false,
      required: false,
      ...options,
      immediateBindingUpdate: this.immediateBindingUpdate
    });
  }

  private formRadioGroup(
    fieldName: string,
    values: string | FormInputSelectValue[],
    title?: string,
    options?: {
      readOnly?: boolean;
      required?: boolean;
      events?: InputEventMap;
      class?: string;
      slot?: string;
      slotted?: TemplateResult;
    }
  ) {
    const classes = this.getClasses(fieldName);
    if (options) {
      options.class = `${classes ?? ''} ${options.class ?? ''}`.trim();
      if (isEmptyOrSpace(options?.class)) {
        options.class = undefined;
      }
    } else options = { class: classes };
    return formRadioGroup(fieldName, title, values, this.dataBinding, this.dataTracker, {
      ...options,
      immediateBindingUpdate: this.immediateBindingUpdate
    });
  }

  private formSelect(
    fieldName: string,
    values: string | FormInputSelectValue[],
    options: {
      title?: string;
      readOnly?: boolean;
      required?: boolean;
      placeholder?: string;
      events?: InputEventMap;
      slot?: string;
      slotted?: TemplateResult;
      class?: string;
      toolTip?: string | TemplateResult;
    }
  ) {
    return formSelect(
      fieldName,
      options?.placeholder ?? '',
      options.title,
      values,
      this.dataBinding,
      this.dataTracker,
      options?.readOnly,
      options.required,
      options.events?.change,
      this.immediateBindingUpdate,
      options?.slot,
      options?.slotted,
      options?.class,
      options?.toolTip,
      this.toolTipMaxSize
    );
  }

  private formPicker(
    fieldName: string,
    display: string,
    clickEvent: EventPickerResult | DropDownMenuDefinition,
    title?: string,
    required = false,
    readonly = false,
    slotted?: TemplateResult,
    events?: PickerEventMap
  ) {
    const displayFieldName = this.dataBinding.field(`${fieldName}-displaycontainer`);
    const clPick = noParalellExecutionAsync(async (e: Event) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const newValue = await (clickEvent as EventPickerResult)();
      if (newValue) {
        this.updatePicker(fieldName, newValue);
      }
      events?.change?.(e);
    });
    const label = title ? `${title}` : nothing;
    const classes = `webmodule-control-left-label  ${this.getClasses(fieldName)}`;
    const isDropdown = clickEvent instanceof DropDownMenuDefinition;

    const buttonTemplate = isDropdown
      ? html`
          <webmodule-dropdown hoist slot="suffix">
            <webmodule-icon-button slot="trigger" name="fas-ellipsis" library="fa"></webmodule-icon-button>
            <webmodule-menu> ${clickEvent.templates()}</webmodule-menu>
          </webmodule-dropdown>
        `
      : html` <webmodule-icon-button
          @click=${clPick}
          slot="suffix"
          name="fas-ellipsis"
          library="fa"
        ></webmodule-icon-button>`;
    const blurEvent = (e: Event) => {
      events?.blur?.(e);
    };
    return html`
      ${this.textHidden(fieldName)}
      <webmodule-input
        id=${displayFieldName}
        class=${classes}
        type="text"
        label=${label}
        ?required=${required}
        ?readonly=${true}
        ?filled=${readonly}
        value=${display}
        size="small"
        @webmodule-blur=${blurEvent}
        @webmodule-focus=${events?.focus}
        @mouseenter=${events?.mouseenter}
        @mouseleave=${events?.mouseleave}
        @webmodule-keydown=${events?.keydown}
        @webmodule-keyup=${events?.keyup}
      >
        ${slotted}${buttonTemplate}
      </webmodule-input>
    `;
  }

  private formSelectReadonly(
    fieldName: string,
    values: string | FormInputSelectValue[],
    title?: string,
    classes?: string
  ) {
    return formSelect(
      fieldName,
      '',
      title,
      values,
      this.dataBinding,
      this.dataTracker,
      true,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      classes
    );
  }
}

export class FormInputAssistantWithTracker extends FormInputAssistant {
  private ui: HTMLElement | (() => HTMLElement);
  private internalDataTracker: DataTracker;
  public data: any = {};

  clear() {
    this.data = {};
    this.internalDataTracker.clear();
  }

  constructor(
    ui: HTMLElement | (() => HTMLElement),
    forceReadonly: boolean | (() => boolean) = false,
    initializer?: (fiaw: FormInputAssistantWithTracker) => void
  ) {
    super(() => this.internalDataTracker, forceReadonly, true);
    this.ui = ui;
    this.internalDataTracker = new DataTracker(new DataBinding(this.ui));
    initializer?.(this);
  }

  add(fieldName: string, fieldType: FieldType, value: EventValue, nullable = false) {
    this.data[fieldName] = value;
    this.dataTracker.addDynamic(
      fieldName,
      fieldType,
      () => this.data[fieldName],
      value => (this.data[fieldName] = value),
      { nullable: nullable }
    );
  }
}
