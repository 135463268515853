import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { fileToBase64 } from '../../../webmodule-common/other/blob-converters';
import { getApiFactory } from '../../api/api-injector';

export interface ImageUploadOptions {
  dataBinding: DataBinding;
}

/**
 * Defines basic information about a virtual file.
 */
export interface VirtualFile {
  /** Name of the file. */
  name: string;
  /** The file extension of the file. */
  extension: string;
  /** The content of the file. */
  content: string;
  /** The content type of the file. */
  type: string;
  /** The virtual filepath for the file. */
  filePath: string;
}

export async function uploadVirtualFile(virtualFile: VirtualFile) {
  return await getApiFactory().blob().updateFileByVirtualPath({
    newVirtualPath: virtualFile.filePath,
    oldVirtualPath: '',
    data: virtualFile.content
  });
}
